import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {
  Observable,
  TimeoutError,
  catchError,
  map,
  tap,
  throwError,
  timeout,
} from 'rxjs';
import { AESEncryptDecryptServiceService } from 'src/app/shared/services/aesencrypt-decrypt-service.service';
import { environment } from 'src/environment/environment';
import { EventBusService, TokenStorageService } from '../services';
import { EmitEvent } from '../helpers';
import { Events } from '../enums';
import { CustomMessageService } from 'src/app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';

const apiUrl = environment.env.apiBaseUrl;
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private requestCount: number = 0;

  constructor(
    private aes: AESEncryptDecryptServiceService,
    private tokenStorageService: TokenStorageService,
    private eventBus: EventBusService,
    private toastService: CustomMessageService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      !req.url.includes('users/self') &&
      !req.url.includes('users/notification_message/')
    ) {
      this.eventBus.emit(new EmitEvent(Events.LOADING_START));
    }
    let authReq = req;
    if (
      this.tokenStorageService.getTokenSession &&
      !authReq.url.includes('/assets/i18n')
    ) {
      // req.headers.set('Authorization', `Bearer ${this.tokenStorageService.getTokenSession}`).set('Content-Type', 'application/x-www-form-urlencoded')
      let headers =
        !authReq.url.includes('/auth/email-verify') &&
        !authReq.url.includes('/auth/reset-password')
          ? this.addHeader
          : { Authorization: '' };
      authReq = req.clone({
        url: `${apiUrl}${req.url}`,
        setHeaders: headers,
      });
    } else if (!authReq.url.includes('/assets/i18n')) {
      let reqParam = {
        url: `${apiUrl}${req.url}`,
      };
      if (
        this.router.url.includes('/auth/set-password') &&
        this.route.snapshot.queryParams['token']
      ) {
        Object.assign(reqParam, { setHeaders: { Authorization: '' } });
      }
      authReq = req.clone(reqParam);
    } else {
      authReq = req;
    }

    /*  FOR FILE UPLOAD REFERENCE
      *  if (req.headers.get('skip')) {
      *    req = req.clone({
      *      headers: req.headers.set('Content-Type', 'application/octet-stream'),
      *    });
      }
    */

    switch (authReq.method) {
      case 'GET':
        this.aes.encryptData(authReq.urlWithParams);
        break;
      case 'PUT':
        authReq = authReq.clone({
          body: { data: this.aes.encryptData(authReq.body) },
        });
        break;
      case 'POST':
        // && !authReq.url.includes('/agg/users')
        if (
          !authReq.url.includes('/connect/token') &&
          !authReq.url.includes('setting/enum/vehicles/types') &&
          !authReq.url.includes('setting/coupon/add') &&
          !authReq.url.includes('setting/social_links/add') &&
          !authReq.url.includes('/management-committee') &&
          !authReq.url.includes('/setting/enum/vehicles/brands') &&
          !authReq.url.includes('/campaigns') &&
          !authReq.url.includes('/agg/users') &&
          !authReq.url.includes('/add-doc') &&
          !authReq.url.includes('/update-rc') &&
          !authReq.url.includes('/users/questions') &&
          !authReq.url.includes('enum/vehicles/addVehicle') && 
          !authReq.url.includes('setting/geofence/upload_excel')
        ) {
          authReq = authReq.clone({
            body: { data: this.aes.encryptData(authReq.body) },
          });
        }
        break;
      case 'DELETE':
        authReq = authReq.clone({
          body: { data: this.aes.encryptData(authReq.body) },
        });
        break;
      case 'PATCH':
        if (
          authReq.body &&
          !authReq.url.includes('/campaigns') &&
          !authReq.url.includes('/agg/users') &&
          !authReq.url.includes('/update-doc') &&
          !authReq.url.includes('/setting/enum/vehicles/brands') &&
          !authReq.url.includes('setting/social_links') &&
          !authReq.url.includes('/setting/coupon/') &&
          !authReq.url.includes('/profiles/update-rc') &&
          !authReq.url.includes('setting/enum/vehicles/types') &&
          !authReq.url.includes('/users/questions')
        ) {
          authReq = authReq.clone({
            body: { data: this.aes.encryptData(authReq.body) },
          });
        }
        break;
    }

    this.requestCount++;
    return next.handle(authReq).pipe(
      timeout(this.defaultTimeout || 30000),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.requestCount--;
          // if (this.requestCount <= 0) {
          //   this.eventBus.emit(new EmitEvent(Events.LOADING_END));
          // }
          if (
            !req.url.includes('users/self') &&
            !req.url.includes('users/notification_message/')
          ) {
            this.eventBus.emit(new EmitEvent(Events.LOADING_END));
          }
          if (event && event.body && event.body.data) {
            const data = JSON.parse(this.aes.decryptData(event.body.data));
            event = event.clone({ body: { data } });
          }
          return event;
        } else {
          return event;
        }
      }),
      catchError((error) => {
        let errorMessage: any = '';
        this.requestCount--;
        if (error instanceof TimeoutError) {
          errorMessage = 'Timeout occured please try again';
        } else {
          errorMessage =
            error && error.error && error.error.data
              ? JSON.parse(this.aes.decryptData(error.error.data))
              : 'Something went wrong!';
        }

        return throwError(() => {
          if (error.status === 401) {
            this.toastService.show(
              'Session Timeout Please re-login',
              'error',
              'Error',
            );
            this.eventBus.emit(new EmitEvent(Events.SIGNOUT));
            return this.eventBus.emit(new EmitEvent(Events.LOADING_END));
          } else {
            if (this.requestCount <= 0) {
              this.eventBus.emit(new EmitEvent(Events.LOADING_END));
            }
            return [
              errorMessage,
              this.toastService.showHttpMessages(
                errorMessage['code']
                  ? errorMessage['code'].code
                    ? errorMessage['code'].code
                    : errorMessage['code']
                  : '',
                'error',
                'Error',
              ),
              error.error.data
                ? JSON.parse(this.aes.decryptData(error.error.data))
                : '',
            ];
          }
        });
      }),
    );
  }

  private get addHeader() {
    if (
      this.router.url.includes('/auth/set-password') &&
      this.route.snapshot.queryParams['token']
    ) {
      return {
        Authorization: 'Bearer ' + this.route.snapshot.queryParams['token'],
      };
    } else {
      return {
        Authorization: 'Bearer ' + this.tokenStorageService.getTokenSession,
      };
    }
  }
}
